import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {CustomHeader} from "../../components/header";
import {Content} from "antd/es/layout/layout";
import {Flex, Layout, Spin} from "antd";
import {CustomFooter} from "../../components/footer";
import {InstructorPage} from "./InstructorPage";
import {useAxios, useInitialUseEffect} from "../../Util/Util";

export const Instructor = () => {
    const { id } = useParams();
    const [instructor, setInstructor] = useState(false);
    const {AxiosGet} = useAxios()

    useInitialUseEffect(()=>{
        AxiosGet("instructors.php", {id: id}, (response) => setInstructor(response.data.value[0]))
    })

    const getInstructorContent = () => {
        return !(instructor) ?
            (<>
                    <CustomHeader activeTab=""/>
                    <Content style={{width: '100%', height: "100%", display: 'flex'}}>
                        <Flex style={{flex:1}} align={"center"} justify={"center"}>
                            <Spin />
                        </Flex>
                    </Content>
                </>
            ) :
            (<>
                <CustomHeader activeTab="" solidBackground={false}/>

                    <InstructorPage instructor={instructor}/>

                <CustomFooter/>
            </>);
    }

    return (
        <Layout className="layout" style={{width: '100vw'}}>
                {getInstructorContent()}
        </Layout>);
}


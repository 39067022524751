import {Card, Col, Row} from "antd";
import {GrCertificate} from "react-icons/gr";
import Title from "antd/es/typography/Title";
import {TeamOutlined} from "@ant-design/icons";
import {LiaToolsSolid} from "react-icons/lia";
import React from "react";

export const About = () => {
    return (<Row gutter={[36, 24]}>
        <Col xs={24} xl={8}>
            <Card cover={
                <><GrCertificate className={"about-icon"}/></>
            }
                  style={{ backgroundColor: 'transparent', border: "none"}}
                  bodyStyle={{textAlign: 'center', border: "none"}}
            >
                <Title className={"about-title"} level={4}>Up-to-Date Curriculum</Title>

                We use <em className={"value-highlight"}>current, nationally accepted, best-practice techniques and
                tactics</em> to address the evolving requirements within the field of law enforcement.


            </Card>
        </Col>
        <Col xs={24} xl={8}>
            <Card cover={
                <><TeamOutlined className={"about-icon"}/></>
            }

                  style={{ backgroundColor: 'transparent', border: "none"}}
                  bodyStyle={{textAlign: 'center', border: "none"}}>

                <Title className={"about-title"} level={4}>Team Building</Title>

                Our courses include <em className={"value-highlight"}>hands-on group activities</em> that foster strong
                relationships among team members, creating a foundation of trust and mutual understanding.
            </Card>
        </Col>
        <Col xs={24} xl={8}>
            <Card cover={
                <><LiaToolsSolid className={"about-icon"}/></>
            }
                  style={{  backgroundColor: 'transparent', border: "none"}}
                  bodyStyle={{textAlign: 'center', border: "none"}}>

                <Title className={"about-title"} level={4}>Leadership Development</Title>

                Participants undergo targeted training that hones their abilities to <em className={"value-highlight"}>
                lead and manage teams effectively</em> in high-pressure and dynamic situations.

            </Card>
        </Col>
    </Row>);
}
import {useNavigate} from "react-router-dom";
import {App} from "antd";
import axios from "axios";
import {API_URL} from "./Constants";
import {useEffect, useState} from "react";

export const getStates = () => {
    return [
        {value:"Alabama"},
        {value: "Alaska"},
        {value: "Arizona"},
        {value: "Arkansas"},
        {value: "California"},
        {value: "Colorado"},
        {value: "Connecticut"},
        {value: "Delaware"},
        {value: "Florida"},
        {value: "Georgia"},
        {value: "Hawaii"},
        {value: "Idaho"},
        {value: "Illinois"},
        {value: "Indiana"},
        {value: "Iowa"},
        {value: "Kansas"},
        {value: "Kentucky"},
        {value: "Louisiana"},
        {value: "Maine"},
        {value: "Maryland"},
        {value: "Massachusetts"},
        {value: "Michigan"},
        {value: "Minnesota"},
        {value: "Mississippi"},
        {value: "Missouri"},
        {value: "MontanaNebraska"},
        {value: "Nevada"},
        {value: "New Hampshire"},
        {value: "New Jersey"},
        {value: "New Mexico"},
        {value: "New York"},
        {value: "North Carolina"},
        {value: "North Dakota"},
        {value: "Ohio"},
        {value: "Oklahoma"},
        {value: "Oregon"},
        {value: "Pennsylvania"},
        {value: "Rhode Island"},
        {value: "South Carolina"},
        {value: "South Dakota"},
        {value: "Tennessee"},
        {value: "Texas"},
        {value: "Utah"},
        {value: "Vermont"},
        {value: "Virginia"},
        {value: "Washington"},
        {value: "West Virginia"},
        {value: "Wisconsin"},
        {value: "Wyoming"}
    ]
}

export const useAxios = () => {
    const { message } = App.useApp();
    const messageKey = 'axiosToastKey';

    axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

    const handleException = (aResponse, aMessage, aCustomMessages) => {
        const defaultExceptionHandling = () => {
            message.error({content: aMessage, key: messageKey});
        }

        const errorCode = aResponse?.response?.status;
        if(errorCode !== undefined){
            if(aCustomMessages){
                if(aCustomMessages[errorCode]){
                    message.error({content: aCustomMessages[errorCode], key: messageKey});
                } else {
                    defaultExceptionHandling()
                }
            }
        } else {
            defaultExceptionHandling()
        }
    }

    const AxiosPost = (aSubject, aCustomMessages, aUrl, aData, aCustomErrorMessages, aSuccessCallback, aFailureCallback) => {
        const loadingMessage = aCustomMessages.loading ??  `Creating ${aSubject}...`;
        message.loading({content: loadingMessage, key: messageKey});

        axios({
            method: 'POST',
            url: `${API_URL}/${aUrl}`,
            data: aData,
            config: { headers: {"Access-Control-Allow-Origin": '*'}}
        }).then((response) => {
            const successMessage = aCustomMessages.success ??  `${aSubject} successfully created.`;
            message.success({content: successMessage, key: messageKey})
            if(aSuccessCallback){
                aSuccessCallback(response)
            }
        }).catch((response) => {
            const failureMessage = aCustomMessages.failure ??  `${aSubject} was not successfully created. Please try again later.`;
            handleException(response, failureMessage, aCustomErrorMessages);

            if(aFailureCallback){
                aFailureCallback(response)
            }
        });
    };

    const AxiosGet = (aUrl, aParams, aSuccessCallback, aFailureCallback) => {
        axios({
            method: 'GET',
            url: `${API_URL}/${aUrl}`,
            config: { headers: {"Access-Control-Allow-Origin": '*'}},
            params: {
                ...aParams
            }
        }).then((response) => {
            if(aSuccessCallback){
                aSuccessCallback(response)
            }
        }).catch((response) => {
            handleException(response, 'There was an issue retrieving the data for this page. Please try again later.')
            if(aFailureCallback){
                aFailureCallback(response)
            }
        });
    };

    const AxiosPut = (aSubject, aUrl, aId, aData, aSuccessCallback, aFailureCallback) => {
        message.loading({content: `Updating ${aSubject}...`, key: messageKey})

        aData['put'] = true;
        aData['id'] = aId;

        axios({
            method: 'POST',
            url: `${API_URL}/${aUrl}`,
            data: aData,
            config: { headers: {"Access-Control-Allow-Origin": '*'}}
        }).then((response) => {
            message.success({content: `${aSubject} successfully updated.`, key: messageKey})
            if(aSuccessCallback){
                aSuccessCallback(response)
            }
        }).catch((response) => {
            handleException(response, `${aSubject} was not successfully updated. Please try again later.`)
            if(aFailureCallback){
                aFailureCallback(response)
            }
        });
    };

    const AxiosDelete = (aSubject, aUrl, aIds, aSuccessCallback, aFailureCallback) => {
        message.loading({content: `Deleting ${aSubject}...`, key: messageKey})
        axios({
            method: 'POST',
            url: `${API_URL}/${aUrl}`,
            data: {
                ids: aIds,
                delete: true
            },
            config: { headers: {"Access-Control-Allow-Origin": '*'}}
        }).then((response) => {
            message.success({content: `${aSubject} successfully deleted.`, key: messageKey})
            if(aSuccessCallback){
                aSuccessCallback(response)
            }
        }).catch((response) => {
            handleException(response, `${aSubject} was not successfully deleted. Please try again later.`)
            if(aFailureCallback){
                aFailureCallback(response)
            }
        });
    };

    return {AxiosPut, AxiosPost, AxiosGet, AxiosDelete}
}

export const useCommonAxiosCalls = () => {
    const {AxiosGet, AxiosPost} = useAxios();


    const GetInstructors = (setInstructors) => {
        const successCallback = (response) => {
            const instructors = response.data.value;
            instructors.forEach(instructor => {
                instructor['label'] = instructor.first_name + " " + instructor.last_name
                instructor['value'] =  instructor.id.toString();
            })
            setInstructors(instructors);
        };

        AxiosGet( "instructors.php", {visible: true}, successCallback)
    };

    const GetCourses = (setCourses) => {
        const successCallback = (response) => {
            const offeredCourses = response.data.value;
            offeredCourses.forEach(oc => {
                oc['key'] = oc.id
            })
            setCourses(offeredCourses);
        };

        AxiosGet( "courses.php", {}, successCallback);
    };

    const SendEmail = (options, customMessage, successCallback, failureCallback) => {
        AxiosPost("Email", customMessage, "email.php", options, {}, successCallback, failureCallback);
    }

    const GetSettings = (setSettings) => {
        AxiosGet("settings.php", {}, (response) => setSettings(response.data.value[0]));
    }

    return {GetInstructors, GetCourses, SendEmail, GetSettings}
}



// this is for a call to 'useEffect' that happens once as an initialization tool
export const useInitialUseEffect = (aEffect) => {
    const [didInit, setDidInit] = useState(false);

    useEffect(() => {
        if (!didInit) {
            setDidInit(true);
            // ✅ Only runs once per app load
            aEffect();
        }
    }, []);
}

export const getMonthAbbrev = (monthNum) => {
    // eslint-disable-next-line default-case
    switch (monthNum){
        case 0:
        case "01":
            return "Jan";
        case 1:
        case "02":
            return "Feb"
        case 2:
        case "03":
            return "March"
        case 3:
        case "04":
            return "April"
        case 4:
        case "05":
            return "May"
        case 5:
        case "06":
            return "June"
        case 6:
        case "07":
            return "July"
        case 7:
        case "08":
            return "Aug"
        case 8:
        case "09":
            return "Sept"
        case 9:
        case "10":
            return "Oct"
        case 10:
        case "11":
            return "Nov"
        case 11:
        case "12":
            return "Dec"
    }
}
import React, {useState} from "react";
import {Button, Card, Col, Flex, Row, Typography} from "antd";
import Meta from "antd/es/card/Meta";
import {Content} from "antd/es/layout/layout";
import {ArrowRightOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../Util/Constants";

export const OfferedCourses = ({offeredCourses}) => {
    const [courses, setCourses] = useState();
    const navigate = useNavigate();

    const goToCourse = (id) => {
        navigate(`/course/${id}`)
    }

    const { Text } = Typography;
    const getCourseCard = (course) => {
        return (
            <Col key={course.id} style={{display: 'flex', alignItems: "stretch"}} xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                <Card
                    style={{flex: 1}}
                    hoverable={true}
                    cover={<div style={{height: 200}}><img alt={""} style={{width:'100%', objectFit: 'cover'}} src={API_URL.concat("/" + course.image)} /></div>}
                    onClick={() => goToCourse(course.id)}
                >
                    <>
                        <Meta
                            title={
                                <div>
                                    <Flex justify={"space-between"} align={"flex-start"}>
                                        <Text style={{margin: "0", whiteSpace: 'break-spaces'}}>{course.name}</Text>
                                        <Button style={{display: "flex"}} type="ghost" icon={<ArrowRightOutlined/>}></Button>
                                    </Flex>
                                </div>
                        }
                        />
                    </>

                </Card>
            </Col>
        );
    }

    const getCourseCards = () => {
        const courseCards = [];
        offeredCourses.forEach(course => {
            courseCards.push(getCourseCard(course));
        });
        setCourses(courseCards);
    }


    return (
        <Content>
            <Row gutter={[24, 24]}>
                {courses ? courses : getCourseCards()}
            </Row>
        </Content>
    )
}
import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Flex,
    Form,
    Input,
    InputNumber,
    Layout,
    Radio,
    Row,
    Select,
    Space
} from "antd";
import {CustomHeader} from "../components/header";
import {Content} from "antd/es/layout/layout";
import {CustomFooter} from "../components/footer";
import React, {useState} from "react";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import dayjs from "dayjs";
import {Success} from "./success";
import {render} from "@react-email/render";
import HostAppSubmissionEmail from "../emails/HostAppSubmissionEmail";

import {ADMIN_URL} from "../Util/Constants";
import {getStates, useAxios, useCommonAxiosCalls, useInitialUseEffect} from "../Util/Util";

export const Host = () => {
    const [offeredCourses, setOfferedCourses] = useState();
    const [successful, setSuccessful] = useState(false);
    const [settings, setSettings] = useState(false);
    const [form] = Form.useForm();
    const {GetCourses, GetSettings, SendEmail} = useCommonAxiosCalls();
    const {AxiosPost} = useAxios();

    useInitialUseEffect(() => {
        GetCourses(setOfferedCourses);
        GetSettings(setSettings);
        window.scrollTo(0, 0);
    });

    const onFinish = (values) => {
        values.submission_date = dayjs().format("MM/DD/YYYY").toString();
        const customMessages = {
            success: 'Host application successfully submitted.',
            failure: 'Host application could not be submitted at this time. Please try again later or email one of our instructors.',
            loading: 'Submitting host application...'
        }

        const successCallback = (response) => {
            SendEmail(getOptions(response.data, values), customMessages, () => {
                setSuccessful(true)
                form.resetFields();
                window.scrollTo(0, 0);
            })

        }

        AxiosPost("Host Application", customMessages, "courseApplications.php", values, {}, successCallback)
    };

    const getCourseName = () => {
        const courseId = form.getFieldValue(['requested_course', 'courseId']);
        return offeredCourses.filter(oc => oc.id === courseId)[0].name;
    }

    const getEmailHtml = (id, values) => {
        return render(<HostAppSubmissionEmail
            hostApp={values}
            agencyName={form.getFieldValue(['cohost', 'name'])}
            courseName={getCourseName()}
            firstDate={form.getFieldValue(['general', 'dates', 'first'])}
            secondDate={form.getFieldValue(['general', 'dates', 'second'])}
            thirdDate={form.getFieldValue(['general', 'dates', 'third'])}
            applicationLink={ADMIN_URL + "/hostApplication/" + id}
        />)
    };

    const getOptions = (id, values) => {
        return {
            recipients: [settings.host_app_email],
            subject: 'Host Application Submitted',
            html: `${getEmailHtml(id, values)}`
        }
    };

    return (

            <Layout className="layout" style={{boxSizing: 'border-box'}}>
                <CustomHeader activeTab="host" style={{width: '100%', boxSizing: 'border-box'}}/>
                                        <Content style={{paddingTop: 120, paddingBottom: 80, width: '100%', boxSizing: 'border-box'}}>

                                            <Row align={"center"}>
                                                <Col xs={22} sm={22} md={22} lg={16} xl={16} xxl={16} >
                                                    {!successful
                                                        ?
                                                        <Card bodyStyle={{display:'flex', flexDirection: 'column'}}>
                                                            <Flex style={{paddingBottom: 24, paddingTop: 24, flexWrap:'wrap', flex:1}} align={"flex-end"} gap={"middle"}>

                                                                <Row align={'center'} style={{flex:1}} gutter={[36,48]}>
                                                                    <Col style={{display:'flex', alignItems:'stretch', justifyItems:'center'}} xs={20} sm={18} md={16} lg={12} xl={9} xxl={7}>
                                                                        <Flex align={"flex-end"}>
                                                                            <img style={{width:'100%', height: 'auto'}} alt='logo' src='/logo.png' />
                                                                        </Flex></Col>
                                                                    <Col flex={1}>
                                                                        <Flex vertical={true} justify={"flex-end"}>
                                                                            <Title style={{marginTop:0}} level={4}>Host a Course</Title>
                                                                            <Text>Use this form to propose hosting a training course at your facility.</Text>
                                                                        </Flex>
                                                                    </Col>
                                                                    <Col span={18}><div style={{width:'100%', margin:'auto', padding:0}} className={"header-underline"}></div></Col>
                                                                </Row>
                                                            </Flex>



                                                            <Form
                                                                layout="vertical"
                                                                name="nest-messages"
                                                                onFinish={onFinish}
                                                                form={form}
                                                                scrollToFirstError={{
                                                                    behavior: 'smooth',
                                                                    block: 'center',
                                                                    inline: 'center',
                                                                }}
                                        >


    <Title level={5}>Co-Host Agency Information</Title>
    <Divider></Divider>
    <Form.Item name={['cohost', 'name']} label="Agency Name" rules={[{required: true}]}>
        <Input/>
    </Form.Item>

    <Row gutter={16}>
        <Col flex={1}>
            <Form.Item name={['cohost', 'contact', 'name', 'title']} label="Contact Title">
                <Input/>
            </Form.Item>
        </Col>
        <Col flex={1}>
            <Form.Item name={['cohost', 'contact', 'name', 'first']} label="Contact First Name" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        </Col>
        <Col flex={1}>
            <Form.Item name={['cohost', 'contact', 'name', 'last']} label="Contact Last Name" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        </Col>
    </Row>

    <Row gutter={16}>
        <Col flex={1}>
            <Form.Item name={['cohost', 'contact', 'email']} label="Contact Email" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        </Col>
        <Col flex={1}>
            <Form.Item name={['cohost', 'contact', 'phone']} label="Contact Phone Number" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        </Col>
    </Row>

    <Form.Item name={['cohost', 'address', 'text']} label="Mailing Address" rules={[{required: true}]}>
        <Input/>
    </Form.Item>

    <Row gutter={16}>
        <Col flex={1}>
            <Form.Item name={['cohost', 'address', 'city']} label="City" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        </Col>
        <Col >
            <Form.Item name={['cohost', 'address', 'state']} label="State" rules={[{required: true}]}>
                <Select showSearch
                        style={{ width: '100%' }}
                        placeholder="Select State..."
                        popupMatchSelectWidth={false}
                        options={getStates()}
                />
            </Form.Item>
        </Col>
        <Col flex={1}>
            <Form.Item name={['cohost', 'address', 'zip']} label="Zip" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        </Col>
    </Row>



                                            <Title level={5}>Requested Training Course</Title>
                                            <Divider></Divider>
                                            <Row>
                                                <Form.Item style={{flex:1}} name={['requested_course', 'courseId']} label="Requested Training Course" rules={[{required: true}]}>
                                                    <Select
                                                        mode="single"
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        placeholder="Select Course"
                                                        options={offeredCourses}
                                                        fieldNames={{
                                                            label: 'name',
                                                            value: 'id',
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Row>
                                            <Row>
                                                <Form.Item style={{flex:1}} name={['requested_course', 'preferred_instructors']} label="Preferred Instructor Name (If Any)">
                                                    <Input/>
                                                </Form.Item>
                                            </Row>
                                            <Row>
                                                <Form.Item style={{flex:1}} name={['requested_course', 'contract']} label="Will your agency require Phronesis to sign a contract or provide verification of insurance?" rules={[{required: true}]}>
                                                    <Input/>
                                                </Form.Item>
                                            </Row>

                                            <Title level={5}>Proposed Dates & Course Type</Title>
                                            <Divider></Divider>

                                            <Row gutter={16}>
                                                <Col flex={1}>
                                                    <Form.Item name={['general', 'dates', 'first']} label="1st Choice Start Date" rules={[{required: true}]}
                                                               getValueFromEvent={(e) => e?.format("MM/DD/YYYY")}
                                                               getValueProps={(e) => ({
                                                                   value: e ? dayjs(e) : "",
                                                               })}>
                                                        <DatePicker style={{display: "flex"}} format={"MM/DD/YYYY"}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <Form.Item name={['general', 'dates', 'second']} label="2nd Choice Start Date" rules={[{required: true}]} getValueFromEvent={(e) => e?.format("MM/DD/YYYY")}
                                                               getValueProps={(e) => ({
                                                                   value: e ? dayjs(e) : "",
                                                               })}>
                                                        <DatePicker style={{display: "flex"}} format={"MM/DD/YYYY"}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <Form.Item name={['general', 'dates', 'third']} label="3rd Choice Start Date" rules={[{required: true}]}getValueFromEvent={(e) => e?.format("MM/DD/YYYY")}
                                                               getValueProps={(e) => ({
                                                                   value: e ? dayjs(e) : "",
                                                               })}>
                                                        <DatePicker style={{display: "flex"}} format={"MM/DD/YYYY"}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Form.Item name={['general', 'type']} label="Type Of Course" rules={[{required: true}]}>
                                                    <Radio.Group >
                                                        <Space direction="vertical">
                                                            <Radio value={'open'}><b>Open</b>: Any sworn law enforcement officer can attend and each is responsible for payment. This type of course is advertised on our website and other LE training sites. Two complimentary slots are given to the co-host agency.</Radio>
                                                            <Radio value={'closed'}><b>Closed</b>: Your agency pays for the entire cost of ALL registered officers. This type of course is not advertised. Purchase order # and invoicing instructions required.</Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Row>
                                            <Row>
                                                <Form.Item name={['general', 'number_of_students']} label="How Many Students Will Your Agency be Sending to this Course?" rules={[{required: true}]}>
                                                    <InputNumber/>
                                                </Form.Item>
                                            </Row>

                                            <Title level={5}>Training Site Address</Title>
                                            <Divider></Divider>

                                            <Form.Item name={['address', 'text']} label="Training Site Address" rules={[{required: true}]}>
                                                <Input/>
                                            </Form.Item>
                                            <Row gutter={16}>
                                                <Col flex={1}>
                                                    <Form.Item name={['address', 'city']} label="City" rules={[{required: true}]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                                <Col >
                                                    <Form.Item name={['address', 'state']} label="State" rules={[{required: true}]}>
                                                        <Select showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="Select State..."
                                                                popupMatchSelectWidth={false}
                                                                options={getStates()}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <Form.Item name={['address', 'zip']} label="Zip" rules={[{required: true}]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Title level={5}>Point of Contact Who Will be Present at Course</Title>
                                            <Divider></Divider>

                                            <Row gutter={16}>
                                                <Col flex={1}>
                                                    <Form.Item name={['contact', 'name', 'title']} label="Title">
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <Form.Item name={['contact', 'name', 'first']} label="First Name" rules={[{required: true}]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <Form.Item name={['contact', 'name', 'last']} label="Last Name" rules={[{required: true}]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={16}>
                                                <Col flex={1}>
                                                    <Form.Item name={['contact', 'email']} label="Email" rules={[{required: true}]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <Form.Item name={['contact', 'phone']} label="Phone Number" rules={[{required: true}]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Title level={5}>Training Logistics</Title>
                                            <Divider></Divider>

                                            <Form.Item name={['logistics', 'access']} label="Please Select What Your Facility Will Have Access To:" valuePropName="checked">
                                                <Checkbox.Group>
                                                    <Space direction="vertical">
                                                        <Checkbox value={'Adequate space with tables and chairs'}>Adequate space with tables and chairs</Checkbox>
                                                        <Checkbox value={'White board with markers'}>White board with markers</Checkbox>
                                                        <Checkbox value={'Multimedia or LCD projector & screen'}>Multimedia or LCD projector & screen</Checkbox>
                                                    </Space>
                                                </Checkbox.Group>
                                            </Form.Item>

                                            <Form.Item name={['logistics', 'advertising']} label="Are You Able to Assist with Advertising?" rules={[{required: true}]}>
                                                <Radio.Group>
                                                    <Space direction="vertical">
                                                        <Radio value={'yes'}>Yes</Radio>
                                                        <Radio value={'no'}>No</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Flex justify={'right'}>
                                                <Form.Item >
                                                    <Button type="primary" htmlType="submit">
                                                        Submit Application
                                                    </Button>
                                                </Form.Item>
                                            </Flex>
                                        </Form>
                                    </Card>
                                :
                                <>
                                    <Flex align={'middle'} justify={'center'}>
                                        <Success title={"Host application successfully submitted"}
                                                 subtitle={"You will receive an email from one of our instructors with more information shortly."}></Success>
                                    </Flex>
                                </>
                            }

                        </Col>
                    </Row>
                </Content>
                <CustomFooter />
            </Layout>
    );
}
import React, {useState} from "react";
import {Badge, Button, Col, Flex, List, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import {MdOutlineOpenInNew} from "react-icons/md";
import {LockOutlined} from "@ant-design/icons";
import {getMonthAbbrev, useAxios, useInitialUseEffect} from "../../Util/Util";

export const Schedule = ({scheduledCoursesParam, courseId}) => {
    const navigate = useNavigate();
    const [scheduledCourses, setScheduledCourses] = useState(false);
    const {AxiosGet} = useAxios();

    const getScheduledCourses = () => {
        let url = `scheduledCourses.php`;

        if(courseId){
            url = url.concat(`?id=${courseId}`);
        }

        AxiosGet(url, {}, (response) => {
            const courses = response.data.value;
            setScheduledCourses(courses);
        });
    }

    useInitialUseEffect(()=>{
        if (scheduledCoursesParam) {
            setScheduledCourses(scheduledCoursesParam);
        } else {
            getScheduledCourses();
        }
    })

    const goToCourse = (id) => {
        navigate(`/course/${id}`)
    }
    return (
        <Content>
            <List
                dataSource={scheduledCourses ? scheduledCourses : getScheduledCourses()}
                header={<List.Item>
                    <Row gutter={{xs: 12, sm: 24}} style={{width: '100%', flex:1}}>
                        <Col xs={7} md={7} lg={7}>
                            Date
                        </Col>
                        <Col xs={7} sm={8} md={8}>
                            Course
                        </Col>
                        <Col xs={4} sm={5} md={5}>
                            Availability
                        </Col>
                        <Col xs={6} sm={4} style={{display: 'flex'}}>

                        </Col>
                    </Row>

                </List.Item>}
                renderItem={(item) => {
                    if(item.start_date == null || item.end_date == null) {
                        return '';
                    }

                    const dateSeparator = /-|\//;

                    const startDate = item.start_date?.split(dateSeparator).join('/');
                    const endDate = item.end_date?.split(dateSeparator).join('/');


                    const available = !parseInt(item.full);

                    return (
                        <List.Item key={item.id}>
                            <Row gutter={{xs: 12, sm: 24}} style={{width: '100%', flex:1}}>
                                <Col xs={7} md={7} lg={7}>
                                    <Flex style={{flex:1, flexWrap: 'wrap'}}>
                                        <span style={{marginRight: 6}}>{startDate}<span style={{marginLeft: 6}}>-</span></span>
                                        <span>{endDate}</span>
                                    </Flex>
                                </Col>
                                <Col xs={7} sm={8} md={8} >
                                    <div>
                                        {item.courseDeleted ? <div>{item.courseName}</div> :
                                            <Button style={{display: 'flex', whiteSpace: 'break-spaces', textAlign: 'left', padding:0, width: '100%', height: '100%'}} type={"link"} onClick={() => goToCourse(item.courseId, item.courseName)}><span style={{marginBottom: 'auto'}}>{item.courseName}</span></Button>}
                                        {item.location}
                                    </div>

                                </Col>
                                <Col xs={4} sm={5} md={5}>
                                    {available ? <><Badge status="success" style={{paddingRight: 4}}/> Slots Available</> : <><Badge status="default" style={{paddingRight: 4}}/> Full</>}
                                </Col>
                                <Col xs={6} sm={4} style={{display: "flex", justifyContent: 'flex-end'}}>
                                    {item.open ? <Button disabled={!available} className={'registration-button'} type={"primary"} target="_blank" href={item.registration_link}>
                                        Register <MdOutlineOpenInNew className={"open-in-btn"}></MdOutlineOpenInNew>
                                    </Button> : <div style={{lineHeight: '19px'}}><span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><LockOutlined style={{marginRight:4, height: '19px'}}/><span style={{wordBreak: 'keep-all', whiteSpace: 'break-spaces'}}>Closed Course</span></span></div>}
                                </Col>
                            </Row>
                        </List.Item>
                    )
                }}
            />
        </Content>
    )
}
import {Html} from "@react-email/html";
import {Body, Container, Head, Img, Text} from "@react-email/components";
import * as React from "react";

import {
    Section,
    Hr
} from "@react-email/components";
import {PHRONESIS_URL} from "../Util/Constants";
import {ReactElement} from "react";

interface HostAppSubmissionEmailProps {
    hostApp?: Object;
    agencyName?: string;
    courseName?: string;
    firstDate?: string;
    secondDate?: string;
    thirdDate?: string;
    applicationLink?: string;
}

export const HostAppSubmissionEmail = ({
                                           hostApp,
                                           agencyName,
                                           courseName,
                                           firstDate,
                                           secondDate,
                                           thirdDate,
                                           applicationLink,
                                       }: HostAppSubmissionEmailProps) => {

    const getSectionTitle = (title: string) => {
        if(title === 'cohost') return 'cohost information';
        if(title === 'address') return 'training address';
        if(title === 'contact') return 'day of training contact';
        return title;
    };

    const getFieldKey = (section: string, key: string) => {
        if(key === 'courseId') return 'Course Name';
        if(key === 'text') return 'address';
        if(key === 'first' && section !== 'general') return 'first name';
        if(key === 'last') return 'last name';
        if(key === 'first' && section === 'general') return 'first proposed date';
        if(key === 'second') return 'second proposed date';
        if(key === 'third') return 'third proposed date';
        if(key === 'type') return 'open or closed';
        if(key === 'number_of_students') return 'number of students agency will provide';
        if(key === 'contract') return 'will agency need a contract or proof of insurance';
        if(!isNaN(Number(key))) return 'agency will provide'
        return key;
    };

    const getFieldValue = (key: string, value: string) => {
        if(key === 'courseId') return courseName;
        return value;
    };


    const capitalize = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };

    const getLabel = (word: string) => {
        return capitalize(word.replaceAll('_', ' '));
    };

    const getHostAppSections = () => {
        const sections: ReactElement[] = [];

        if (hostApp != null) {
            Object.entries(hostApp).forEach((entry: [string, any]) => {
                if(entry[0] !== 'submission_date') {
                    sections.push(getHostAppSection(entry));
                }
            });
        }

        return sections;
    }

    const getHostAppSection = (entry: [string, any]) => {
        const getSectionDetails = (object:object) => {
            const sectionDetails: ReactElement[] = [];
            Object.entries(object).forEach((entry: [string, any]) => {
                sectionDetails.push(...getSectionDetail(entry[0], entry[1]));
            });
            return sectionDetails;
        }

        const getSectionDetail = (key: string, value: any) => {

            if(typeof value === 'object'){
                return getSectionDetails(value);
            } else {
                return (
                    [<Text style={text}>{getLabel(getFieldKey(entry[0], key))}: {getFieldValue(key, value)} </Text>]
                );
            }
        }

        return (
            <>
                <Hr/>
                <Body style={hostAppSection}>
                    <Text style={hostAppSectionTitle}>{getLabel(getSectionTitle(entry[0]))}</Text>
                    {getSectionDetails(entry[1])}
                </Body>
            </>

        );
    };

    return (
        <Html>
            <Head />
            <Body style={main}>
                <Container style={container}>
                    <Img
                        src={`${PHRONESIS_URL}/logo.png`}
                        width="auto"
                        height="45"
                        alt="Phronesis"
                    />
                    <Section>
                        <Text style={text}>Dear Phronesis Team Member,</Text>
                        <Text style={text}>
                            {agencyName} has submitted an application to host {courseName} on {firstDate}, {secondDate}, or {thirdDate}.
                            See details below, and <a style={link} href={applicationLink}>view application status in admin portal</a>.
                        </Text>
                        {getHostAppSections()}
                    </Section>
                </Container>
            </Body>
        </Html>
    );
};

export default HostAppSubmissionEmail;

const main = {
    backgroundColor: "#000000",
    padding: "10px 0",
};

const container = {
    backgroundColor: "#262626",
    border: "1px solid #262626",
    padding: "45px",
};

const text = {
    fontSize: "16px",
    fontFamily:
        "'Open Sans', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    fontWeight: "300",
    color: "#e5e5e5",
    lineHeight: "26px",
};

const link = {
    color: "#0D94A6",
};

const hostAppSection = {
    fontSize: "16px",
    fontFamily:
        "'Open Sans', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    fontWeight: "300",
    color: "#e5e5e5",
    lineHeight: "26px",
    padding: "32px 16px 32px 16px"
};

const hostAppSectionTitle = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#e5e5e5",
}